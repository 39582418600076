import React from "react"
import ServicesScreen from "@screens/services/services"
import { Inside } from "@screens/services/components"

const Services = () => (
  <ServicesScreen>
    <Inside />
  </ServicesScreen>
)

export default Services
